import React, { useEffect, useState } from "react"

import gsap, { Power2 } from "gsap/gsap-core"

import MainSvg from "../assets/svg/main.svg"
import Logo from "../assets/svg/logo.svg"

import "./index.scss"
import { CSSPlugin } from "gsap"
import { useRef } from "react"
import isTouchDevice from "is-touch-device"
import SEO from "../components/seo"

var assert = require("assert")

gsap.registerPlugin(CSSPlugin)

gsap.defaults({ duration: 2, ease: Power2.easeInOut })

const IndexPage = () => {
  const containerRef = useRef()
  const [buildingsTl] = useState(gsap.timeline({ paused: true }))
  const [lastUpdate, setLastUpdate] = useState(0)

  useEffect(() => {
    if (isTouchDevice()) {
      setTimeout(() => buildingsTl.paused(false), 1200)
    }
  }, [])

  useEffect(() => {
    const pageContainer = document.querySelector(".page-container")

    const mainSvg = document.querySelector(".main-svg")
    const contactDetails = document.querySelector(".contact-details")
    const copyLine = document.querySelector(".copy-line")

    const mainBuilding1 = document.querySelector(".main-building-1")
    const mainBuilding2 = document.querySelector(".main-building-2")

    const greenBuildings = document.querySelectorAll(".green-building")
    const orangeBuildings = document.querySelectorAll(".orange-building")
    const pinkBuildings = document.querySelectorAll(".pink-building")

    const tl = new gsap.timeline()
    tl.add("pageload")
      .set(pageContainer, { visibility: "visible" })
      .from(mainBuilding1, { y: "+=800" }, "start")
      .from(mainBuilding2, { y: "+=800" }, "start")

    buildingsTl
      .add("start")
      .from(pinkBuildings, { y: "+=800" }, "start")
      .from(orangeBuildings, { y: "+=800" }, "start+=0.4")
      .from(greenBuildings, { y: "+=800" }, "start+=0.8")
    //   .from(contactDetails, { opacity: 0, y: 20, duration: 0.8 }, "-=0")
    //   .from(copyLine, { opacity: 0, y: 20, duration: 0.8 }, "-=0.4")
  }, [])

  const progressTimeline = () => {
    const newProgress = buildingsTl.progress() + 0.004
    buildingsTl.progress(newProgress)
  }
  useEffect(() => {
    window.addEventListener("mousemove", progressTimeline)
    return () => {
      window.removeEventListener("mousemove", progressTimeline)
    }
  }, [])
  useEffect(() => {
    console.log(containerRef.current)
    if (!containerRef.current) return
    const setPageContainerHeight = () => {
      console.log("resize")
      containerRef.current.style.height = window.innerHeight + "px"
    }
    setPageContainerHeight()
    window.addEventListener("resize", setPageContainerHeight)
    return () => {
      window.removeEventListener("resize", setPageContainerHeight)
    }
  }, [containerRef.current])
  return (
    <div
      className="page-container w-full relative flex flex-col justify-between"
      ref={containerRef}
    >
      <SEO title="Commercial property sorted" />
      <div className="flex flex-wrap w-full">
        <div className="w-full md:w-1/3 lg:w-1/4 p-6 md:p-12">
          <Logo className="w-40" />
        </div>
        <div className="contact-details w-full md:w-1/3 lg:w-1/4 p-6 md:p-12">
          <div>
            Hadley McLachlan <br />
            Director <br />
          </div>
          <div className="md:mt-2">
            +64 204 133 5422 <br />
            hadley@mclachlan.nz
          </div>
        </div>
        <div className="copy-line md:w-1/3 lg:w-1/2 p-6 md:p-12">
          <div>Commercial property management and investment.</div>
        </div>
      </div>
      <div className="w-full">
        <MainSvg className="main-svg" />
      </div>
    </div>
  )
}

export default IndexPage
